import React, { useState, useEffect } from "react";
import Loader from "../assets/images/loader.svg";
import ActivateImg from "../assets/images/activate.svg";
import { useParams } from "react-router-dom";
import { activateCommissionLink } from "../api";

const ActivatePage = () => {
  const [loading, setLoading] = useState(true);

  const { merchantId, deviceId, storeUrl } = useParams(); // Access parameters

 


  useEffect(() => {
    setLoading(true);
    activateCommissionLink(merchantId, deviceId, storeUrl)
      .then((response) => {
        if (response.redirectUrl) {
          const url = new URL(response.redirectUrl);
          setLoading(false);
          setTimeout(() => {
            window.location.href = url.toString(); // Redirect to the modified URL
          }, 1000);
        } else {
          console.error("No URL found in response");
        }
      })
      .catch((err) => {
        console.log("Auth0 login error", err);
      });
  }, []);

  console.log("merchantId, deviceId, storeUrl", merchantId, deviceId, storeUrl);

  return (
    <div className="w-full h-screen flex items-center justify-center">
      {loading ? (
        <div className="flex flex-col items-center space-y-2">
          <img src={Loader} alt="loading" className="w-16 h-16 animate-spin" />
          <h2 className="font-semibold text-lg text-[#212121]">
            Activating Cashback...
          </h2>
        </div>
      ) : (
        <div className="space-y-2 flex justify-center flex-col items-center">
          <img src={ActivateImg} alt="activate" className="w-52 h-52" />
          <h2 className="font-semibold text-lg text-[#212121]">
            Cashback Activated
          </h2>
          <h2 className="font-normal text-sm text-black">Happy Shopping!</h2>
        </div>
      )}
    </div>
  );
};

export default ActivatePage;
