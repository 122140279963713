import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;
// const baseUrl = "https://api.cleocards.shop/api/v1";

const configWithToken = (token) => ({
  headers: { Authorization: `Bearer ${token}` },
});

export const getAllStores = (all = false) => {
  const params = all ? `?all=true` : "";

  
  return axios.get(`${baseUrl}/stores${params}`).then(({ data }) => data);
};

export const getMyBalance = (token) => {
  return axios
    .get(`${baseUrl}/balances/me`, configWithToken(token))
    .then(({ data }) => data);
};



export const getAllCodes = () => {
  return axios.get(`${baseUrl}/codes`).then(({ data }) => data);
};

export const getAllDeals = () => {
  return axios.get(`${baseUrl}/deals`).then(({ data }) => data);
};

export const getMyCodes = (token) => {
  return axios
    .get(`${baseUrl}/codes/me`, configWithToken(token))
    .then(({ data }) => data);
};

export const getCode = (token, id) => {
  return axios
    .get(`${baseUrl}/codes/${id}`, configWithToken(token))
    .then(({ data }) => data);
};

export const deleteCode = (token, id) => {
  return axios
    .delete(`${baseUrl}/codes/${id}`, configWithToken(token))
    .then(({ data }) => data);
};

export const unlistCode = (token, id) => {
  return axios
    .patch(`${baseUrl}/codes/${id}`, { listed: false }, configWithToken(token))
    .then(({ data }) => data);
};

export const getMyTransactions = (token) => {
  return axios
    .get(`${baseUrl}/transactions/me`, configWithToken(token))
    .then(({ data }) => data);
};

export const uploadCode = (token, payload) => {
  return axios
    .post(`${baseUrl}/codes`, payload, configWithToken(token))
    .then(({ data }) => data);
};

export const sellCode = (token, payload) => {
  return axios
    .post(`${baseUrl}/codes/sell`, payload, configWithToken(token))
    .then(({ data }) => data);
};

export const sellUnsupportedCode = (token, payload) => {
  return axios
    .post(`${baseUrl}/codes/sellUnsupported`, payload, configWithToken(token))
    .then(({ data }) => data);
};

export const listCode = (token, { codeId, ...payload }) => {
  return axios
    .post(`${baseUrl}/codes/${codeId}/list`, payload, configWithToken(token))
    .then(({ data }) => data);
};

export const createPlaidLinkToken = (token) => {
  return axios
    .get(`${baseUrl}/payments/link_token`, configWithToken(token))
    .then(({ data }) => data);
};

export const startPayout = (token, payload) => {
  return axios
    .post(`${baseUrl}/payments/start-payout`, payload, configWithToken(token))
    .then(({ data }) => data);
};

export const payout = (token) => {
  return axios
    .post(`${baseUrl}/payments/payout`, {}, configWithToken(token))
    .then(({ data }) => data);
};

export const subscribeToMarketing = (email) => {
  return axios
    .post(`${baseUrl}/marketing/subscribe`, { email })
    .then(({ data }) => data);
};

export const zapierWebHook = (email) => {
  return axios
    .post(`${baseUrl}/webhooks/zapier-hook`, { email })
    .then(({ data }) => data);
};


export const activateCommissionLink = async (merchantId,deviceId,storeUrl) => {
  return axios
    .get(`${baseUrl}/wildfire/activateCommissionLink/?merchantId=${merchantId}&deviceId=${deviceId}&storeUrl=${storeUrl}`)
    .then(({ data }) => data)
};