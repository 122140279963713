import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import TimeAgo from "react-timeago";

import Navbar from "../../components/Navbar";
import Content from "../../components/Content";
import Footer from "../../components/Footer";
import { BreadcrumbHeader } from "../../components/PageHeader";
import SectionHeading from "../../components/SectionHeading";
import Button from "../../components/Button";
import { useNotification } from "../../components/Notification";

import { centsToDollars } from "../../utils/currency";
import {
  getMyBalance,
  getMyTransactions,
  startPayout,
  payout,
} from "../../api";
import StatusPill from "../../components/StatusPill";

const YourBalance = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const showNotification = useNotification();

  const [balance, setBalance] = React.useState(0);
  const [transactions, setTransactions] = React.useState([]);
  const [processing, setProcessing] = React.useState(false);

  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleMouseEnter = () => {
    if (balance < 2500) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const mergeTransactions = (transactions, wfCommission) => {
    const transactionList = transactions.map((transaction) => ({
      id: transaction.id,
      buyerId: transaction.buyerId,
      amount: transaction.amount,
      completedAt: transaction.completedAt,
      storeTitle: transaction.storeId,
      status: "completed",
      isWfCommission: false,
    }));

    const commissionMap = new Map();

    wfCommission.forEach((comm) => {
      const merchantOrderId = comm.MerchantOrderID;
      console.log("merchantOrderId", merchantOrderId);
      const totalAmount =
        comm.Amounts.reduce(
          (total, item) => total + parseFloat(item.Amount),
          0
        ) / 2;

      if (commissionMap.has(merchantOrderId)) {
        commissionMap.get(merchantOrderId).amount += totalAmount * 100; // Convert to cents
      } else {
        commissionMap.set(merchantOrderId, {
          id: comm.id,
          MerchantOrderID: merchantOrderId,
          amount: totalAmount * 100, // Convert to cents
          completedAt: comm.EventDate,
          storeTitle: comm.MerchantName,
          status: comm.Status,
          isWfCommission: true,
        });
      }
    });

    const wfCommissionList = Array.from(commissionMap.values());

    return [...transactionList, ...wfCommissionList];
  };

  const loadData = React.useCallback(() => {
    getAccessTokenSilently()
      .then((token) =>
        Promise.all([getMyBalance(token), getMyTransactions(token)])
      )
      .then(([{ balance: myBalance }, myTransactions]) => {
        const { transactions } = myTransactions;
        const { WfCommission } = myTransactions;
        const combinedData = mergeTransactions(transactions, WfCommission);
        const sortedData = combinedData.sort(
          (a, b) => new Date(b.completedAt) - new Date(a.completedAt)
        );

        setBalance(myBalance);
        setTransactions(sortedData);
      });
  }, [getAccessTokenSilently]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const handleStartPayout = async () => {
    try {
      setProcessing(true);

      const token = await getAccessTokenSilently();
      const { accountLink } = await startPayout(token, { email: user.email });
      window.location.replace(accountLink);
    } catch (error) {
      console.log(error);

      showNotification({
        title: "Error",
        description: "Sorry something went wrong!",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  const handlePayout = React.useCallback(async () => {
    try {
      setProcessing(true);

      const token = await getAccessTokenSilently();
      await payout(token);

      showNotification({
        title: "Successfully cashed out!",
        description: "You have successfully cashed out your current balance!",
      });
      loadData();
    } catch (error) {
      console.log(error);

      const { type } = error.response?.data || {};

      if (type === "ERR_CODE_NO_BALANCE") {
        showNotification({
          title: "Error",
          description: "Sorry your balance is insufficient!",
          type: "error",
        });
      } else {
        showNotification({
          title: "Error",
          description: "Sorry something went wrong!",
          type: "error",
        });
      }
    } finally {
      setProcessing(false);

      navigate("/balance", { replace: true });
    }
  }, [getAccessTokenSilently, navigate, showNotification, loadData]);

  React.useEffect(() => {
    if (searchParams.get("returning") && !processing) {
      handlePayout();
    }
  }, [processing, searchParams, handlePayout]);

  return (
    <>
      <Navbar />
      <Content>
        <div className="w-full max-w-7xl mx-auto space-y-3">
          <BreadcrumbHeader
            items={[
              { to: "/", label: "Home" },
              { to: "#", label: "Your Balance" },
            ]}
            title="Your Balance"
          />
          <div className="flex mt-10 gap-8 flex-col sm:flex-row">
            <aside className="w-72 max-w-full">
              <div className="rounded-lg shadow p-6 mb-4">
                <p className="text-sm leading-5 font-medium text-gray-500">
                  Today's Balance
                </p>
                <p className="text-3xl leading-9 font-semibold text-gray-900 mt-1">
                  {centsToDollars(balance)}
                </p>
              </div>

              <div className="relative">
                <Button
                  disabled={processing || balance < 2500}
                  onClick={handleStartPayout}
                  className="flex gap-2  py-2 px-4 w-full  max-w-full items-center justify-center  disabled:hover:bg-indigo-600w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:hover:bg-indigo-600"
                >
                  <span>Cash Out</span>
                  {( balance <= 2500) && (
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <path d="M10 2a8 8 0 100 16 8 8 0 000-16zM9 9a1 1 0 112 0v4a1 1 0 11-2 0V9zm1-5a1 1 0 100 2 1 1 0 000-2z" />
                    </svg>
                  )}
                </Button>

                {(processing || balance < 2500) && showTooltip && (
                  <span
                    className="absolute z-10 bg-white text-black text-xs rounded py-2 px-2 top-[40px] left-1/2 transform -translate-x-1/2 transition-opacity duration-200 whitespace-nowrap shadow-lg"
                    style={{ pointerEvents: "none" }}
                  >
                    <div className="w-3 h-3 -mt-[14px] rotate-45 bg-white ml-[122px]"></div>
                    Balance must be over $25 to withdraw
                  </span>
                )}
              </div>
            </aside>

            <main className="flex-1">
              <SectionHeading>Recent transactions</SectionHeading>

              <ul className="divide-y divide-gray-200 mt-6 pr-3.5">
                {transactions.map(
                  ({
                    id,
                    amount,
                    storeTitle,
                    completedAt,
                    isWfCommission,
                    buyerId,
                    status,
                  }) =>
                    !isWfCommission ? (
                      <li key={id} className="py-4">
                        <div className="flex space-x-3">
                          <div className="flex-1 space-y-1">
                            <div className="flex items-center justify-between">
                              {buyerId ? (
                                <h3 className="text-base font-medium text-emerald-600">
                                  +{centsToDollars(amount)}
                                </h3>
                              ) : (
                                <h3 className="text-base font-medium text-red-600">
                                  -{centsToDollars(amount)}
                                </h3>
                              )}
                              <p className="text-sm text-gray-500">
                                <TimeAgo date={completedAt} />
                              </p>
                            </div>
                            <p className="text-sm leading-5 text-gray-900">
                              {buyerId ? (
                                <>
                                  <span className="font-bold">Sold</span>
                                  <span>: {storeTitle} gift card</span>
                                </>
                              ) : (
                                <span className="font-bold">Cashed Out</span>
                              )}
                            </p>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <li key={id} className="py-4">
                        {(status === "PENDING" || status === "CONFIRMED") && (
                          <>
                            <div className="flex space-x-3">
                              <div className="flex-1 space-y-1">
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <h3 className="text-base font-medium text-emerald-600">
                                      +{centsToDollars(amount)}
                                    </h3>
                                    <div className="relative ml-2 group">
                                      <svg
                                        className="w-5 h-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                      >
                                        <path d="M10 2a8 8 0 100 16 8 8 0 000-16zM9 9a1 1 0 112 0v4a1 1 0 11-2 0V9zm1-5a1 1 0 100 2 1 1 0 000-2z" />
                                      </svg>
                                      <div className="absolute bottom-0 flex flex-col hidden  mb-6 group-hover:flex">
                                        <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-nowrap bg-gray-600 shadow-lg rounded-md">
                                          Commissions will be added to your
                                          balance after the pending period has
                                          closed. Typically that’s 30-45 days.
                                        </span>
                                        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600 ml-[6px]"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <p className="text-sm text-gray-500">
                                    <TimeAgo date={completedAt} />
                                  </p>
                                </div>
                                <p className="text-sm leading-5 text-gray-900">
                                  <span className="font-bold">Cashback</span>
                                  <span>: {storeTitle} Purchase</span>
                                </p>
                              </div>
                            </div>
                            <StatusPill status="PENDING" />
                          </>
                        )}

                        {(status === "PAID" || status === "READY") && (
                          <>
                            <div className="flex space-x-3">
                              <div className="flex-1 space-y-1">
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <h3 className="text-base font-medium text-emerald-600">
                                      +{centsToDollars(amount)}
                                    </h3>
                                    <div className="relative ml-2 group">
                                      <svg
                                        className="w-5 h-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                      >
                                        <path d="M10 2a8 8 0 100 16 8 8 0 000-16zM9 9a1 1 0 112 0v4a1 1 0 11-2 0V9zm1-5a1 1 0 100 2 1 1 0 000-2z" />
                                      </svg>
                                      <div className="absolute bottom-0 flex flex-col hidden mb-6 group-hover:flex">
                                        <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-nowrap bg-gray-600 shadow-lg rounded-md">
                                          Your commission has been added to your
                                          wallet! Thank you for using Cleo
                                        </span>
                                        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600 ml-[6px]"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <p className="text-sm text-gray-500">
                                    <TimeAgo date={completedAt} />
                                  </p>
                                </div>
                                <p className="text-sm leading-5 text-gray-900">
                                  <span className="font-bold">Cashback</span>
                                  <span>: {storeTitle} gift card</span>
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </li>
                    )
                )}
              </ul>
            </main>
          </div>
        </div>
      </Content>
      <Footer />
    </>
  );
};

export default withAuthenticationRequired(YourBalance);
